import React, { useEffect, useState } from 'react';
import { GoogleMap , LoadScript, MarkerF} from '@react-google-maps/api';
import ImageGallery from 'react-image-gallery';
import moment from 'moment';
import { useMemo } from "react";


const IMAGES = [{
  originial: 'https://photos.google.com/share/AF1QipO2fQs-5nIyqmTiqvdXl4swP6IoC09oYR43bJHYM9BUqvkWTUFzwfh-eK2coGuRlA?key=UkZIWWdnWC1jTjBQaDVfLTlOazlBWmtfa3VzM1Z3',
  thumbnail: 'https://photos.google.com/share/AF1QipO2fQs-5nIyqmTiqvdXl4swP6IoC09oYR43bJHYM9BUqvkWTUFzwfh-eK2coGuRlA?key=UkZIWWdnWC1jTjBQaDVfLTlOazlBWmtfa3VzM1Z3',
}
  // Add your images here. Each image should be an object with original and thumbnail properties.
];

const position = {
  lat: 53.56568285, // Update these coordinates
  lng: 9.923209101955191, // Update these coordinates
};

const center = {
  lat: 53.56568285, // Update these coordinates
  lng: 9.923209101955191, // Update these coordinates
}

const styles = {
  app: {
    textAlign: 'center',
    fontFamily: 'Arial, sans-serif',
  },
  section: {
    margin: '20px 0',
  },
  countdown: {
    fontSize: '20px',
    color: '#ff0000',
  },
  date: {
    fontSize: '24px',
  },
  time: {
    fontSize: '24px',},
  
  // Add more styles as needed
};






const containerStyle = {
  width: '400px',
  height: '400px',
  margin: '0 auto',
};

function App() {
  const [timeRemaining, setTimeRemaining] = useState('');
  const endDate = '2023-06-10T00:00:00';

  useEffect(() => {
    const interval = setInterval(() => {
      calculateCountdown(endDate);
    }, 1000);
    return () => clearInterval(interval);
  }, [endDate]);

  const calculateCountdown = endDate => {
    const diff = moment.duration(moment(endDate).diff(moment()));
    const days = diff.days();
    const hours = diff.hours();
    const minutes = diff.minutes();
    const seconds = diff.seconds();
    const countdown = `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds remaining`;
    setTimeRemaining(countdown);
  }

  return (
    <div className="App" style={styles.app}>
      <h1>Christians Überraschung</h1>

      <section id="details" style={styles.section}>
        <h2>Event Details</h2>
        <p style={styles.date}>Date: [2026-06-10]</p>
        <p style={styles.date}>Time: [13:45]</p>
        <p style={styles.countdown}>Countdown: {timeRemaining}</p>
      </section>

      <section id="location" >
        <h2>Location</h2>
        <LoadScript   googleMapsApiKey="AIzaSyDHtvqKydMEh_4NtYXixIerqXtbD-fkl5I">
 <GoogleMap
   mapContainerStyle={containerStyle}	
  center={center}
  zoom={10}
>
 <MarkerF position={{ lat: 53.56568285,lng: 9.923209101955191 }} />
</GoogleMap>
        </LoadScript>
      </section>

      <section id="photos" style={styles.section}>
        <h2>Lustig wird es</h2>
        <img src={'https://lh3.googleusercontent.com/pw/AJFCJaW-D2X6cS7l-k17FsrObPqIytOhrIp8K33JAqX1CCfz8-ppJNKHmywmceoeFBUaWwCzSXbbCo1vlXhfovqE_SYi8YAs-xMou7EFL3SAD4tHkvo2xFDNOG9eRR6Hmveb6GgTyw6Vu8uHw5jL3PGdHfX7WA=w1141-h1521-s-no?authuser=0'} style={{ width: '25%', height: '25%' }} />
      </section>

      <section id="event" style={styles.section}>
        <h2>Was machen wir??</h2>
        <div>
          <h4>
            Moin Christian, am 10.6 treffen wir uns an der obigen Adresse. Anschließend gehen wir zur XXXXXXX der  
           YYYYYYY. Dann sehen wir weiter ..... (Musste rausfinden)
          </h4>
        </div>  
        <h1>Herzlichen Glückwunsch Du sechsarmige Krake</h1>
      </section>
    </div>
  );
}

export default App;
